// import React, { Component, createRef } from 'react';
// import { withRouter } from "react-router-dom";
// import {Modal, Row, Col} from "react-bootstrap";
// import  Cookies from "js-cookie";
// // import ReCAPTCHA from "react-google-recaptcha";
// import storage from "../../../Storage";
// import GoogleBtn from "./Google";
// import Forget from "./Forget";
// import socket from "../../../Socket";
// import {Event, wait, decode, encode, sendNotfication, RECAPTCHA} from "../../../Helper";
// import C from "../../../Constant";

// class Login extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isLogged: storage.getKey('logged'),
//             show: this.props.show ? this.props.show : false,
//             forgetPage: false,
//             username: '',
//             password: '',
//             // recaptcha: false,
//             status: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn'
//         };
//         this.handleShow = this.handleShow.bind(this);
//         this.handleClose = this.handleClose.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);

//         // this.recaptchaRef = createRef();
//     }

//     componentDidMount() {
//         socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
//         Event.on('login_form', () => {
//             this.handleShow();
//         })
//     }

//     setLogin = (data) => {
//         if (data.status === true)
//         {
//             sendNotfication('Successfully Login, Please Wait...', 'success','bottom-left');
//             Cookies.set("session", data.token, {expires: 14});
//             storage.setKey('logged', true);
//             storage.setKey('token', data.token);
//             storage.setKey('name', data.name);
//             storage.setKey('avatar', data.avatar);
//             storage.setKey('email', data.email);
//             storage.setKey('credit', data.credit);
//             storage.setKey('room', data.room);
//             storage.setKey('friends', data.friends);

//             wait(500).then(() => {
//                 window.location.reload();
//             })

//         } else {
//             this.setState({submitted: false, disabled: false});
//             sendNotfication(data.status, 'success','bottom-left');
//         }
//     };

//     handleShow(e){
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose(){
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
//     }

//     handleSubmit = async (e) => {
//         e.preventDefault();

//         this.setState({ submitted: true, disabled: true, status: this.props.t('please_wait') });

//         const { username, password } = this.state;

//         if (!(username && password)){
//             this.setState({ disabled: false, status: false });
//             return;
//         }

//         //Execute Recaptcha Token
//         // const token = await this.recaptchaRef.current.executeAsync();

//         wait(200).then(() => {
//             socket.emit(C.LOGIN_USER, encode({
//                 username: username,
//                 password: password,
//                 recaptcha: 'google'
//             }));
//         })
//     }

//     forgetPass = (e) => {
//         this.setState({ forgetPage: !this.state.forgetPage })
//     };

//     // recaptchaChange = (value) => {
//     //     this.setState({ recaptcha: value });
//     // };

//     register = (value) => {
//         this.handleClose();
//         Event.emit('register_form');
//     };

//     back = (value) => {
//         this.setState({ forgetPage: false })
//     };

//     render() {
//         let { t } = this.props; 
//         return (
//             <>
//                 <button className={'btn btn-transparent btn-round btn-sm no-shadow text-white mr-2 font-weight-bold'} onClick={e => this.handleShow(e)}>
//                     {t('login')}
//                 </button>
//                 <Modal
//                     size="md"
//                     centered={true}
//                     backdrop="static"
//                     show={this.state.show}
//                     onHide={this.handleClose}
//                     aria-labelledby="login-md-modal"
//                     className={'modalAuth animated ' + this.state.effect }
//                 >
//                     <Modal.Header>
//                          {this.state.forgetPage &&
//                             <button onClick={this.back} className="btn btn-transparent cpt hvw p-0">
//                                 <i className="fa fa-chevron-left fonts-18 aligdsn-top mr-3 mt-1" />
//                             </button>
//                          }
//                         <button type="button" className="close p-2" onClick={this.handleClose}>
//                             <i className={'mdi mdi-close'}/>
//                         </button>
//                     </Modal.Header>
//                     <Modal.Body className="auth-modal p-0">
//                         <div className="m-auto">
//                          {!this.state.forgetPage &&
//                             <div>
//                                 <div className="text-center">
//                                 {/*{this.state.recaptcha}*/}
//                                     <img src="/assets/images/lg.png" className={'img-fluid auth-logo'} alt="Logo"/>
//                                     <div className="text-center auth-logo-text">
//                                         <p className="mt-0 mb-3 mt-3 font-new text-white font-15">
//                                             {t('mega profit')}
//                                                 <i className="mdi mdi-dots-horizontal mx-2 font-18 align-middle" />
//                                             {t('fair games')}
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div className="px-4">
//                                     <form className="form-horizontal auth-form my-4" onSubmit={ (e) => { this.handleSubmit(e) }}>
//                                         <div className="form-group mb-2">
//                                             <div className="input-group">
//                                                 <div className="input-group-append">
//                                                     <span className="input-group-text bgp">{t('username')}</span>
//                                                 </div>
//                                                 <input type="text"
//                                                        className="form-control"
//                                                        value={this.state.username}
//                                                        autoComplete="off"
//                                                        onChange={e => this.setState({ username: e.target.value })}
//                                                        style={{ height: 40 }}

//                                                 />
//                                                 {this.state.submitted && !this.state.username &&
//                                                     <div className="help-block">{t('username_is_required')}</div>
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div className="form-group mb-2">
//                                             <div className="input-group">
//                                                 <div className="input-group-append">
//                                                     <span className="input-group-text bgp">{t('password')}</span>
//                                                 </div>
//                                                 <input type="password"
//                                                        className="form-control"
//                                                        value={this.state.password}
//                                                        autoComplete="off"
//                                                        onChange={e => this.setState({ password: e.target.value })}
//                                                        style={{ height: 40 }}
//                                                 />
//                                                 {this.state.submitted && !this.state.password &&
//                                                     <div className="help-block">{t('password_is_required')}</div>
//                                                 }
//                                             </div>
//                                         </div>
//                                         <div className="text-center">
//                                             <button className="btn btn-auth btn-block font-weight-bold no-shadow" disabled={this.state.disabled}>
//                                                 {
//                                                     this.state.disabled &&
//                                                     <span className="spinner-grow spinner-grow-sm mr-1" role="loading"></span>
//                                                 }
//                                                 <i className="mdi mdi-login mr-1 float-left font-18" /> {t('login to site')}
//                                             </button>
//                                         </div>
//                                         <div className="mt-3">
//                                             <a href="#" className="" onClick={this.register}>
//                                                 Or Register a new account
//                                             </a>
//                                         </div>
//                                         {/*<ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA} />*/}
//                                     </form>
//                                     <Row className="text-center mb-4">
//                                         {/* <Col md="6" className="my-1">
//                                             <GoogleBtn />
//                                         </Col> */}
//                                         <Col md="6" className="my-1">
//                                             <button onClick={this.forgetPass} className="btn btn-block btn-outline-dark shadow-none">
//                                                 <i className="mdi mdi-information mr-1" /> {t('rest password')}
//                                             </button>
//                                         </Col>
//                                     </Row>
//                                 </div>
//                             </div>
//                          }
//                         {this.state.forgetPage &&
//                              <Forget t={t} />
//                         }
//                         </div>
//                     </Modal.Body>
//                 </Modal>
//             </>
//         );
//     }
// }

// export default withRouter(Login);


import React, { Component,useState,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import Cookies from 'js-cookie';
import storage from '../../../Storage';
import socket from '../../../Socket';
import { Event, wait, decode, encode, sendNotfication, isEmail} from '../../../Helper';
import C from '../../../Constant';
import GoogleBtn from "./Google";
import { ArrowLeft } from 'lucide-react';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1e2124;
    color: white;
    border-radius: 18px;
    overflow: hidden;
  }
`;

const ModalHeader = styled.div`
  background-color: #43B30B;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #2f7d08; // Added border
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display:flex;
  justify-content:flex-end;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledInput = styled.input`
  background-color: #2b2f33;
  border: 1px solid #3a3f44;
  border-radius: 12px;
  color: white;
  padding: 14px;
  &::placeholder {
    color: #6c757d;
  }
  &:focus {
    outline: none;
    border-color: #43B30B;
    box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
  }
`;

const StyledButton = styled.button`
  background-color: #8c52ff;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
  }
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const SocialLoginButton = styled.button`
  background-color: #2b2f33;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;


const StyledSignInButton = styled.div`
  font-size: 16px;
  color:#43B30B !important;
  background-color: transparent;
  border: none;
  border-radius: 28px;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
`;

const Labeltext = styled.div`
color:#696F79;
font-size:14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content:center;
`;
const Button = styled.button`
  padding: 20px 30px;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 480px) {
    padding: 10px 15px;
    font-size: 14px;
  }
`;

const SignInButton = styled(Button)`
  background-color: #8b5cf6;
  color: white;
  border: none;
  width: 100%;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 80px;
    padding: 8px 10px;
    font-size: 12px;
  }

  &:hover {
    background-color: #7c3aed;
  }
`;
const ModalContent = styled.div`
    background: #1a1b23;
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 480px;
    position: relative;
`;
const SignUpButton = styled(Button)`
  background-color: #30353C;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 120px;

  @media (max-width: 768px) {
    max-width: 100px;
  }

  @media (max-width: 480px) {
    max-width: 70px;
    padding: 8px 10px;
    font-size: 12px;
  }

  &:hover {
    background-color: #374151;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: linear-gradient(135deg, #2a2a3c 0%, #1a1a2e 100%);
  border-radius: 16px;
  width: 90%;
  max-width: 480px;
  position: relative;
  padding: 2rem;
  animation: ${fadeIn} 0.3s ease-out;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3);
`;

const BackButton = styled.button`
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #9ca3af;
  transition: color 0.2s ease;

  &:hover {
    color: #ffffff;
  }
`;

const CloseButtonF = styled.button`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #9ca3af;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
`;

const LogoContainer = styled.div`
  text-align: center;
  margin: 1rem 0 2.5rem;
`;

const Logo = styled.img`
  width: 96px;
  height: 96px;
  margin: 0 auto 1rem;
`;

const Title = styled.h2`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: #e5e7eb;
  font-size: 0.875rem;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-size: 1rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }

  &::placeholder {
    color: #6b7280;
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: #ffffff;
  font-weight: 600;
  padding: 0.875rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
  margin-top: 1rem;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.3);
  }

  &:active {
    transform: translateY(0);
  }
`;
const LoadingSpinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 0.8s linear infinite;
  margin-left: 8px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
const ForgotPasswordDialog = ({ onClose, onBack}) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // Setup socket listener
    socket.on(C.RESET_PASSWORD, data => resetPassword(decode(data)));

    // Cleanup listener on unmount
    return () => {
      socket.off(C.RESET_PASSWORD);
    };
  }, []);

  const resetPassword = (data) => {
    setDisabled(false);
    setLoading(false);

    if (data.status) {
      sendNotfication(
        'your password sended to email', 
        'success',
        'top-center'
      );
      onClose(); // Close modal on success
    } else {
      sendNotfication(
       'this email is not registred on our system', 
        'warning',
        'top-center'
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmail(email)) {
      return sendNotfication(
        'please_enter_valid_email_address', 
        'warning',
        'top-center'
      );
    }

    setDisabled(true);
    setLoading(true);

    try {
      await wait(700);
      socket.emit(C.RESET_PASSWORD, encode({ email }));
    } catch (error) {
      setDisabled(false);
      setLoading(false);
      sendNotfication(
        'an_error_occurred', 
        'error',
        'top-center'
      );
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <BackButton onClick={onBack}>
          <ArrowLeft size={24} />
        </BackButton>

        <CloseButton onClick={onClose}>×</CloseButton>

        <LogoContainer>
          <Logo src="/assets/images/lg.png" alt="Logo" />
          <Title>Get Your Password in Your Email</Title>
        </LogoContainer>

        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              required
              disabled={disabled}
            />
          </FormGroup>

          <SubmitButton 
            type="submit"
            disabled={disabled}
          >
            <ButtonContent>
              Send Password Reset Link
              {loading && <LoadingSpinner />}
            </ButtonContent>
          </SubmitButton>
        </Form>
      </ModalContainer>
    </ModalOverlay>
  );
};


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            username: '',
            password: '',
            status: false,
            submitted: false,
            disabled: false,
            effect: 'zoomIn',
            showForgotPassword: false
        };
    }

    componentDidMount() {
        socket.on(C.LOGIN_USER, data => this.setLogin(decode(data)));
        Event.on('login_form', () => {
            this.handleShow();
        });
    }

    setLogin = (data) => {
        if (data.status === true) {
            sendNotfication('Successfully Login, Please Wait...', 'success', 'bottom-left');
            Cookies.set("session", data.token, { expires: 14 });
            storage.setKey('logged', true);
            storage.setKey('token', data.token);
            storage.setKey('name', data.name);
            storage.setKey('avatar', data.avatar);
            storage.setKey('email', data.email);
            storage.setKey('credit', data.credit);
            storage.setKey('room', data.room);
            storage.setKey('friends', data.friends);

            wait(500).then(() => {
                window.location.reload();
            });
        } else {
            this.setState({ submitted: false, disabled: false });
            sendNotfication(data.status, 'success', 'bottom-left');
        }
    };

    handleShow = () => {
        this.setState({ 
            show: true, 
            effect: 'zoomIn',
            showForgotPassword: false 
        });
    }

    handleClose = () => {
        this.setState({ 
            show: false, 
            showForgotPassword: false,
            effect: 'zoomOut', 
            disabled: false, 
            status: false, 
            submitted: false 
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ submitted: true, disabled: true, status: 'Please wait' });

        const { username, password } = this.state;

        if (!(username && password)) {
            this.setState({ disabled: false, status: false });
            return;
        }

        wait(200).then(() => {
            socket.emit(C.LOGIN_USER, encode({
                username: username,
                password: password,
                recaptcha: 'google'
            }));
        });
    }

    handleBackToLogin = () => {
        this.setState({ 
            showForgotPassword: false,
            show: true 
        });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    signup = (e) => {
        e.preventDefault();
        this.handleClose();
        Event.emit('register_form');
    }

    forgetPass = (e) => {
        e.preventDefault();
        this.setState({ 
            showForgotPassword: true,
            show: false 
        });
    }

    render() {
        const { show, username, password, disabled, showForgotPassword } = this.state;

        return (
            <>
                <StyledSignInButton onClick={this.handleShow}>
                    Sign in
                </StyledSignInButton>

                {showForgotPassword ? (
                    <ForgotPasswordDialog 
                        onClose={this.handleClose}
                        onBack={this.handleBackToLogin}
                    />
                ) : (
                    <StyledModal
                        size="md"
                        centered
                        show={show}
                        onHide={this.handleClose}
                        aria-labelledby="login-modal"
                        className={`animated ${this.state.effect}`}
                    >
                        <ModalHeader>
                            <CloseButton onClick={this.handleClose}>×</CloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <div className="text-center mb-4">
                                <h3>BUILD THE BEST CRYPTO CASINO TOGETHER</h3>
                            </div>
                            <StyledForm onSubmit={this.handleSubmit}>
                                <Labeltext>Enter Username</Labeltext>
                                <StyledInput
                                    name="username"
                                    value={username}
                                    onChange={this.handleInputChange}
                                    placeholder="Enter Username"
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Labeltext>Login Password</Labeltext>
                                </div>
                                <StyledInput
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={this.handleInputChange}
                                    placeholder="Login Password"
                                />
                                <div className="text-right">
                                    <button
                                        onClick={this.forgetPass}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'blue',
                                            cursor: 'pointer',
                                            padding: '0',
                                            fontSize: 'inherit',
                                            textDecoration: 'underline'
                                        }}
                                    >
                                        Forgot password?
                                    </button>
                                </div>
                                <ButtonContainer>
                                    <SignInButton type="submit" disabled={disabled}>
                                        {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
                                        Sign In
                                    </SignInButton>
                                    <SignUpButton onClick={this.signup}>Sign up</SignUpButton>
                                </ButtonContainer>
                            </StyledForm>
                            <div className="text-center mt-3 text-lg">
                                <SocialLoginContainer>
                                    <GoogleBtn />
                                </SocialLoginContainer>
                            </div>
                        </ModalBody>
                    </StyledModal>
                )}
            </>
        );
    }
}

export default Login;