import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import storage from "../../../Storage";
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import EngineCarousel from './VipModalCraousel';
import socket from '../../../Socket';
import { decode, encode, getUID } from '../../../Helper';
import C from '../../../../src/Constant'
import { getVipLevelDetails } from './calculateLevel';

const Container = styled.div`
  color: white;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const CarouselContainer = styled.div`
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselTrack = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
`;

const Card = styled.div`
  flex: 0 0 100%;
  scroll-snap-align: start;
  background-color: rgb(32,34,37);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  position: relative;
  
  @media (min-width: 600px) and (max-width: 767px) {
    flex: 0 0 calc(33.333% - 13.333px);
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    flex: 0 0 calc(33.333% - 13.333px);
  }
  
  @media (min-width: 1024px) {
    flex: 0 0 calc(20% - 16px);
  }
`;

const CardTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
  text-wrap: nowrap;
`;

const CardDescription = styled.p`
  font-size: 14px;
  color: #aaa;
`;

const Badge = styled.span`
  background-color: #4a4a4a;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CardContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 8rem;
  border-radius: 8px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 60%;
  object-fit: contain;
  border-radius: 8px;
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BenefitCard = styled.div`
  background-color: #2a2a2a;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
`;

const BenefitImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin-bottom: 15px;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const BenefitTitle = styled.h2`
  color: white;
  margin-bottom: 10px;
  font-size: 16px;
`;

const BenefitDescription = styled.p`
  color: #bbb;
  font-size: 12px;
`;

const CarouselContainerVip = styled.div`
  width: 27%;
  background-color: #1a1a1a;
  border-radius: 10px;
`;

const CardVip = styled.div`
  color: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

const LeftButton = styled(NavigationButton)`
  left: 10px;
`;

const RightButton = styled(NavigationButton)`
  right: 9px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #1e2124;
  padding: 10px;
  border-radius: 8px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #2f3136;
  border-radius: 4px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #5865f2;
  border-radius: 4px;
`;

const LevelIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const LevelCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #5865f2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-right: 8px;
  text-transform:no-wrap;
`;

const LevelText = styled.span`
  color: #72767d;
  font-size: 14px;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: #72767d;
  font-size: 12px;
`;

const MainWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const FlexContainerStar = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;

const CustomModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #17181B;
  color: white;
  width: 900px;
  border-radius: 8px;
  padding: 20px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.div`
  margin: 0;
  font-size:18px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

const cardData = [
  { 
    title: 'Bronze Card', 
    description: 'Smart Player', 
    subtitle: 'You deserve the best',
    imageSrc: '/assets/images/vip-new/vip-1.webp'
  },
  { 
    title: 'Silver Card', 
    description: 'VIP Player', 
    subtitle: 'Enjoy exclusive benefits',
    imageSrc: '/assets/images/vip-new/vip-3.webp'
  },
  { 
    title: 'Gold Card', 
    description: 'Elite Player', 
    subtitle: 'Experience luxury gaming',
    imageSrc: '/assets/images/vip-new/vip-4.png'
  },
  { 
    title: 'Platinum Card', 
    description: 'Elite Player', 
    subtitle: 'Experience luxury gaming',
    imageSrc: '/assets/images/vip-new/vip-5.webp'
  },
  { 
    title: 'Diamond Card', 
    description: 'Elite Player', 
    subtitle: 'Experience luxury gaming',
    imageSrc: '/assets/images/vip-new/vip-2.webp'
  },
];

const cards = [
  {
    title: 'Roll Competition',
    description: 'Try your luck once a day for a spot on our daily top 10 and win some free Doge!',
    badge: 'V03',
    img: 'https://static.nanogames.io/assets/dice.cb7509e5.png'
  },
  {
    title: 'Tip',
    description: 'Send a little (or a lot of) luck to your friends, you\'ve earned the ability to tip others.',
    badge: 'V04',
    img: 'https://static.nanogames.io/assets/money.9316ba83.png'
  },
  {
    title: 'Secret Treasure',
    description: 'The higher rank you are, the more surprising it will be',
    badge: 'V04',
    img: 'https://static.nanogames.io/assets/secret.5ee36c98.png'
  },
  {
    title: 'Private chat',
    description: 'Send messages and chat with your closest friends in private on-site',
    badge: 'V04',
    img: 'https://static.nanogames.io/assets/dice.cb7509e5.png'
  },
  {
    title: 'Raining',
    description: 'Randomly reward players in chat room every 6 hours.',
    badge: 'V04',
    img: 'https://static.nanogames.io/assets/rain.81aa4c59.png'
  },
  {
    title: 'Coindrop',
    description: 'You can now get coin drops from the chatroom for a little extra luck!',
    badge: 'V07',
    img: 'https://static.nanogames.io/assets/coindrop.2d362bea.png'
  },
  {
    title: 'VIP Lounge',
    description: 'Access exclusive VIP areas with special promotions and bonuses.',
    badge: 'V08',
    img: 'https://static.nanogames.io/assets/rain.81aa4c59.png'
  },
  {
    title: 'Priority Support',
    description: 'Get faster responses from our dedicated VIP support team.',
    badge: 'V09',
    img: 'https://static.nanogames.io/assets/dice.cb7509e5.png'
  },
];

const benefits = [
  {
    title: "Unique VIP Transfer Program",
    description: "Hellogames.IO respects and welcomes every distinguished VIP and will give you an exclusive VIP status that you can continue your honor here. Contact us for details.",
    imagePlaceholder: "https://static.nanogames.io/assets/emoji_m.ae3b136b.png"
  },
  {
    title: "Exclusive VIP Host",
    description: "There will be exclusive host for every VIP. Feel free to contact them any time.",
    imagePlaceholder: "https://static.nanogames.io/assets/wallet_m.747f1dcc.png"
  },
  {
    title: "The SVIP Code",
    description: "Only SVIP can get code from exclusive host. Become an SVIP, rock like a superstar and have the spotlight shining on you!",
    imagePlaceholder: "https://static.nanogames.io/assets/start_m.7bbee082.png"
  }
];

const VipClub = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showVipLevelModal, setShowVipLevelModal] = useState(false);
  const [vipDetails, setVipDetails] = useState(null);
  const [progress, setProgress] = useState(0);
  const carouselRef = useRef(null);
  const progressAnimationRef = useRef(null);

  const [wageredAmount, setWagredAmount] = useState(null);

  const animateProgress = (targetProgress) => {
    let currentProgress = 0;
    const duration = 1000; // 1 second animation
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      
      currentProgress = progress * targetProgress;
      setProgress(currentProgress);

      if (progress < 1) {
        progressAnimationRef.current = requestAnimationFrame(animate);
      }
    };

    progressAnimationRef.current = requestAnimationFrame(animate);
  };
  function parseWageredAmount(amountString) {
    if (!amountString) return 0; // Handle null or undefined data
    let cleanedAmount = amountString.replace(/,/g, ''); // Remove any commas
    let parsedAmount = parseFloat(cleanedAmount); // Convert to float
    return isNaN(parsedAmount) ? 0 : parsedAmount; // Return 0 if parsing fails
}

  const getUserInfo = (data) => {
    if (data.status) {
      console.log("data comes", data);

      let waggerAmount =parseWageredAmount(data?.profit).toFixed(4);
      console.log("wagamnounbt",wageredAmount)
      if (waggerAmount) {
        setWagredAmount(waggerAmount);
        const vipDetails = getVipLevelDetails(waggerAmount);
        console.log("vippp",vipDetails);
        setVipDetails(vipDetails);
        // Animate progress based on completion percentage
        animateProgress(parseFloat(vipDetails.completionPercentage));
      }
    } else {
      console.log("called");
    }
  };

  useEffect(() => {
    socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
    socket.emit(C.USER_INFO, encode({ 
      id: getUID, 
      coin: storage.getKey('coin') ? storage.getKey('coin') : '', 
      rate: null, 
      game: 'all', 
      first: true 
    }));

    // Cleanup animation on unmount
    return () => {
      if (progressAnimationRef.current) {
        cancelAnimationFrame(progressAnimationRef.current);
      }
    };
  }, []);

  
  useEffect(()=>{
    
    socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
    socket.emit(C.USER_INFO, encode({ id: getUID, coin: storage.getKey('coin') ? storage.getKey('coin'):'', rate: null, game: 'all', first: true }));

  },[])

  useEffect(() => {
    const username = storage.getKey('name') !== undefined ? storage.getKey('name') : 'Guest';
    console.log(username, "useee");
  }, []);

  const handlePrev = (e) => {
    e.stopPropagation();
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cardData.length - 1));
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setActiveIndex((prevIndex) => (prevIndex < cardData.length - 1 ? prevIndex + 1 : 0));
  };

  const handleCardClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleVipLevelClick = () => {
    setShowVipLevelModal(true);
  };

  const handleCloseVipLevelModal = () => {
    setShowVipLevelModal(false);
  };

  const activeCard = cardData[activeIndex];

  return (
    <Container>
      <CardContainer>
        <div style={{position:'relative', width:'100%'}}>
          <BackgroundImage src="/assets/images/bg_m.png" alt="VIP background" />
          <div style={{position:'absolute', top:'114px', right:'0'}}>
            <div style={{fontSize:'32px', fontWeight:'bold'}}>VIP-CLUB</div>
            <div style={{fontSize:'32px', fontWeight:'bold'}}>Exclusive Player Benefits</div>
            <div style={{width:'600px', fontSize:'16px'}}>
              As a way of showing our gratitude and appreciation to our VIP players, NANOGAMES.IO invites you to join our VIP Club where there is an abundance of gifts, giveaways, higher cash back and unique features. Don't miss out on all the exciting and amazing fun!
            </div>
          </div>
        </div>
      </CardContainer>
      <MainWrapper>
        <CarouselContainerVip>
          <CardVip>
            <img onClick={handleCardClick} src={activeCard.imageSrc} style={{height:'170px', width:'292px', objectFit:'contain'}} alt="VIP Card"/>
            <LeftButton onClick={handlePrev}>
            <ChevronLeft size={20} />
            </LeftButton>
            <RightButton onClick={handleNext}>
              <ChevronRight size={20} />
            </RightButton>
          </CardVip>
        </CarouselContainerVip>
        <InfoContainer>
          <InfoHeader>
            <div>
              <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img className="avatar-panda" src="https://img2.nanogames.io/avatar/589929/s?t=1727260678731" alt="Avatar" />
                <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>{localStorage.getItem("name")}</div>
                <FlexContainerStar>
                  {[...Array(4)].map((_, index) => (
                    <img key={index} style={{width:'20px'}} className="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg==" />
                  ))}
                </FlexContainerStar>
              </div>
            </div>
            <div style={{color:'#49BC0D', fontSize:'18px', fontWeight:'bold'}} onClick={handleVipLevelClick}>About VIP Level</div>
          </InfoHeader>
          <ProgressBarContainer>
        <LevelIndicator>
          <LevelCircle>{vipDetails?.previousVipLevel?.replace('IP', '').replace(' ', '') || 'V0'}</LevelCircle>
          <LevelText>{vipDetails?.vipLevel || 'V01'}</LevelText>
        </LevelIndicator>
        <ProgressBar>
          <Progress progress={progress} />
        </ProgressBar>
        <StatsContainer>
          <span>
            Your current XP: <span style={{color:'white'}}>{vipDetails?.currentWagger || '0'} XP</span> / 
            Total wager: <span style={{color:'white'}}>{vipDetails?.currentWagger || '0.00'} USD</span>
          </span>
          <span>
            Reach <span style={{color:'white'}}>{vipDetails?.waggerNeededForNextLevel || '1'} XP</span> to{' '}
            <span>{vipDetails?.vipLevel || 'VIP1'}</span>
          </span>
        </StatsContainer>
      </ProgressBarContainer>
        </InfoContainer>
      </MainWrapper>

      <Title>VIP & SVIP Unlocked Rights</Title>
      <CarouselContainer ref={carouselRef}>
        <CarouselTrack>
          {cards.map((card, index) => (
            <Card key={index}>
              <Badge>{card.badge}</Badge>
              <img src={card.img} style={{height:'6.2rem', objectFit:'contain'}} alt={index}/>
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </Card>
          ))}
        </CarouselTrack>
      </CarouselContainer>

      <Title>Exclusive VIP & SVIP Benefits</Title>
      <BenefitsGrid>
        {benefits.map((benefit, index) => (
          <BenefitCard key={index}>
            <BenefitImage src={benefit.imagePlaceholder} alt={benefit.title} />
            <BenefitTitle>{benefit.title}</BenefitTitle>
            <BenefitDescription>{benefit.description}</BenefitDescription>
          </BenefitCard>
        ))}
      </BenefitsGrid>

      {showModal && (
        <CustomModal>
          <ModalContent>
            <ModalHeader>
              <ModalTitle>About VIP Cards</ModalTitle>
              <CloseButton onClick={handleCloseModal}>
                <X size={20} />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <img src={activeCard.imageSrc} style={{height:'170px', width:'292px', objectFit:'contain'}} alt="VIP Card"/>
              <h3>{activeCard.title}</h3>
              <p>{activeCard.description}</p>
              <p>{activeCard.subtitle}</p>
            </ModalBody>
          </ModalContent>
        </CustomModal>
      )}

      {showVipLevelModal && (
        <CustomModal>
          <ModalContent>
            <ModalHeader>
              <ModalTitle>About VIP Level</ModalTitle>
              <CloseButton onClick={handleCloseVipLevelModal}>
                <X size={20} />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <EngineCarousel/>
            
            </ModalBody>
          </ModalContent>
        </CustomModal>
      )}
    </Container>
  );
};

export default VipClub;